import { reactive, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import Navigation from "@/presentation/components/navigation/Navigation.vue";
import NoPhoto from "@/assets/images/common/no_photo.png";
import OrganizationProfile from "@/presentation/components/organization-profile/OrganizationProfile.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import { useStore } from "vuex";
import CommonService from "@/services/CommonService";
export default defineComponent({
    name: "Engineer",
    components: {
        Breadcrumb: Breadcrumb,
        Navigation: Navigation,
        OrganizationProfile: OrganizationProfile
    },
    setup: function () {
        var store = useStore();
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var router = useRouter();
        var preload = ref(true);
        var state = reactive({
            uploadfile: {
                image: NoPhoto,
                name: null,
            },
            preload: true,
            listBreadcrumb: [
                { name: "ダッシュボード", route: "/organization/dashboard/projects" },
                { name: "プロフィール", route: "/organization/profile/info" }
            ],
            organizationInfo: {},
            isShowSMSPopup: false,
            accountId: null
        });
        onMounted(function () {
            organizationInteractor
                .getMyProfile()
                .then(function (result) {
                state.preload = false;
                result.data.phone_number = ("" + result.data.phone_number).replace(/\D/g, "");
                state.organizationInfo = result.data;
                store.commit("setOrganizationProfileInfo", result.data);
            })
                .catch(function (error) {
                state.preload = false;
            });
            var user = CommonService.getCookie("user");
            var user_info = user ? JSON.parse(decodeURIComponent(user)) : null;
            var id = user_info && user_info['account_id'] ? user_info['account_id'] : null;
            state.accountId = id;
        });
        return {
            state: state
        };
    }
});
