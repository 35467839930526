<template>
  <div class="my-profile">
    <Navigation />
    <Breadcrumb :breadcrumb="state.listBreadcrumb" />
    <div class="my-profile__container container">
      <div class="my-profile__container__left left-side">
        <OrganizationProfile :accountId="state.accountId" :profile="state.organizationInfo"/>
      </div>
      <div class="my-profile__container__right main-area">
        <div class="my-profile__container__right__tab">
          <router-link :to="{ path: '/organization/profile/info' }">基本情報</router-link>
          <router-link :to="{ path: '/organization/profile/recruiting' }">募集中のプロジェクト</router-link>
          <router-link :to="{ path: '/organization/profile/member' }">担当者一覧</router-link>
        </div>
        <router-view :preload="state.preload" :breadcrumb="state.listBreadcrumb" :organizationInfo="state.organizationInfo" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="@/presentation/views/organization/my-profile/MyProfile.ts" />
<link type="scss" src="@/presentation/views/organization/my-profile/MyProfile.scss" />
